$theme-light-green: #93b233;

@mixin chart-title {
  color: #05315c;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 800;

}
@mixin view-more-link {
  background-color: transparent;
  color: #289df4;
  font-size: 12px;
  padding: 7px 0;
  text-decoration: underline;
  text-transform: capitalize;
  cursor: pointer;
}

@mixin view-more-link-hover {
  background-color: transparent;
  color: $theme-light-green;
}