.tooltip-content {
    visibility: hidden;
    width: 100%; /* Slightly increase width for better readability */
    background-color: rgba(51,51,51,0.9);
    color: #fff;
    text-align: left;
    border-radius: 8px; /* Slightly larger border-radius for a smoother look */
    padding: 12px; /* Increase padding for more space inside */
    position: absolute;
    margin-left: 8px;
    z-index: 9999;
    top: 45%; /* Align tooltip vertically centered with the image */
    left: 0; /* Position it to the right of the image */
    transform: translateY(-55%); /* Center the tooltip vertically */
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
}

.tooltip-container {
    position: relative; /* Ensure the container is positioned relative to position the tooltip correctly */
}

.tooltip-container:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
}

.tooltip-content::after {
    content: "";
    position: absolute;
    top: 50%; /* Position the arrow in the middle of the tooltip */
    left: -15px; /* Position the arrow on the left side of the tooltip, outside the box */
    transform: translateY(-50%);
    border-width: 8px; /* Increase size of the arrow */
    border-style: solid;
    border-color: transparent #333 transparent transparent; /* Arrow color pointing to the right */
}
