@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap');
@import 'vars';

html {

  font-size: 18px !important;

}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background-color: transparent !important;
  font-size: 18px !important;
}


a {
  @include no-outline;

  &:hover,
  &:focus {
    @include no-outline;
  }
}

.edit-post-visual-editor, #root {


  .tabacoo-wrap {
    border-bottom: 1px solid #e0e0e0;
  }

  .tabacoo-wrap .wp-block-column:nth-child(1) {
    padding-right: 15px;
    border-right: 1px solid #e0e0e0;
    margin-right: 20px;
  }

  .tabacoo-wrap .wp-block-column li {
    list-style-type: none;
    color: #333;
    margin-top: 10px;
    position: relative;
  }

  .tabacoo-wrap .wp-block-column li::before {
    content: '';
    position: absolute;
    left: -27px;
    top: 5px;
    width: 15px;
    height: 15px;
    border-radius: 100px;
    background: #5baf95;
  }

  .tabacoo-wrap .wp-block-column li strong {
    font-size: 22px;
    margin-bottom: 5px;
    display: block;
    color: #5baf95;
  }


  iframe {
    margin: 0px;
    padding: 0px;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .ui {
    font-family: $font-family;
  }

  .ui.container.narrow {
    // max-width: 1080px;
  }

  .ui.container.fluid.desktop {
    //max-width: 1366px !important;
  }


  .ui.primary.button {
    background-color: $light-blue;

  }

  .ui.secondary.button {
    background-color: $green;

  }


}

h1.type {
  font-size: 1rem;
  padding: 0;
  margin: 0px;
  color: $light-blue;

  font-family: $font-family;

}


.viz-component {

}

.ui.container.editing {
  margin: 0px !important;
  padding: 5px !important;

  .ui.container {
    width: 100% !important;
    margin-left: 0em !important;
    margin-right: 0em !important;
  }
}

// Map tooltip
.map-tooltip-light {
  font-size: 1em;
  color: #1e1e1e;
  border-radius: 5px;
  background-color: #f7f7f7 !important;
  min-width: 220px;

  .tooltip-content {
    padding: 5px;

    hr {
      margin-left: -5px;
      margin-right: -5px;
      margin-top: 3px;
      margin-bottom: 3px;
      border-bottom: none;
    }

    b {
      color: unset;
    }
  }

  .header {
    padding: 5px;
    border-bottom: 1px solid #DDD;
  }

  .value {
    color: #1e1e1e;
    font-weight: bold;
    margin-left: 5px;
  }

  .footer {
    padding: 5px;

    .confidence {
      font-weight: bold;
      color: #1e1e1e;
    }

    hr {
      margin-left: -5px;
      margin-right: -5px;
      margin-top: 3px;
      margin-bottom: 3px;
    }

    b {
      color: #1e1e1e;
    }
  }
}


.map-tooltip-dark {
  font-size: 1em;
  color: #FFF;
  border-radius: 5px;
  background-color: #5c5c5c !important;
  min-width: 220px;

  .tooltip-content {
    padding: 5px;

    hr {
      margin-left: -5px;
      margin-right: -5px;
      margin-top: 3px;
      margin-bottom: 3px;
      border-bottom: none;
    }
  }

  .header {
    padding: 5px;
    border-bottom: 1px solid #DDD;
  }

  .value {
    color: #FFF;
    font-weight: bold;
    margin-left: 5px;
  }

  .footer {
    padding: 5px;

    .confidence {
      font-weight: bold;
      color: #FFF;
    }

    hr {
      margin-left: -5px;
      margin-right: -5px;
      margin-top: 3px;
      margin-bottom: 3px;
      border-bottom: none;
    }

    b {
      color: #FFF;
    }
  }


}

.ui.container.filter {
  .menu {
    z-index: 1600;
  }
}

.d3MapTooltip {
  font-family: Roboto;
  font-size: 13px;
  border-radius:5%;
  background-color: #3c434a;
  padding:5px;
  opacity: 0.9;
  color: #fff;
  min-width: 200px;
  border: 1px solid #000;

}