@import "./vars.scss";

.top-chart {
  margin: 0 !important;
  background-color: #FFFFFF;
  margin-top: 30px;

  .top-header.row {
    border: 0 !important;
  }

  .top-chart-title {
    @include chart-title;
  }

  .value {
    color: #666666;
    font-size: 19px;
    font-weight: bold;
    text-align: right;

    .currency {
      font-weight: 300;
      display: inline-block;
      margin-right: -5px;
      margin-top: 2px;
    }

    .amount {
      float: left;
      font-family: 'Open Sans', sans-serif;
      font-weight: 800;
    }
  }

  .row {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    font-size: 12px;
    font-weight: bold;
  }

  .inner {
    width: 100%;
  }

  .button.explore-chart-link {
    @include view-more-link;

    &:hover {
      @include view-more-link-hover;
    }
  }
}
